<template>
  <div>
    <div>
      <a-input class="always-normal" allow-clear style="width: 160px;" v-model="input" placeholder="输入商品名称搜索" @keydown.native.enter="handleQuery" />
      <a-button style="margin-left: 15px;" type="primary" @click="handleQuery">搜索</a-button>
    </div>
    <a-spin :spinning="loading">
      <template v-if="data.length">
        <a-product
          v-for="p in data"
          :key="p.productId"
          :data="p"
          :select="value"
          @select="$emit('input', $event)"
        />
        <div style="text-align: right;">
          <a-pagination
            size="small"
            v-model="page.current"
            :total="page.total"
            :page-size="page.size"
            @change="handlePageChange"
          />
        </div>
      </template>
      <a-empty v-else />
    </a-spin>
  </div>
</template>

<script>
import { activity } from '@/api'
import { onMounted, reactive, ref } from '@vue/composition-api'
import AProduct from './AProduct'

export default {
  name: 'SkuSelect',
  components: {
    AProduct
  },
  props: {
    value: Array,
  },
  emits: ['input'],
  setup (props, { emit }) {
    const input = ref('')

    const loading = ref(false)
    const data = ref([])
    const page = reactive({
      current: 1,
      size: 5,
      total: 0
    })
    async function getData () {
      loading.value = true
      const res = await activity.getActivitySelectProductpage({
        name: input.value,
        productType: 4
      })
      loading.value = false
      data.value = res.data
      page.total = res.page.total
    }

    function handlePageChange (current) {
      page.current = current
      getData()
    }

    onMounted(() => {
      getData()
    })

    return {
      input,
      data,
      page,
      loading,
      handleQuery: getData,
      handlePageChange
    }
  }
}
</script>

<style scoped>
.always-normal ::v-deep .ant-input.ant-input.ant-input {
  border-color: #d9d9d9;
}
</style>
