<template>
  <div class="container">
    <div class="card">
      <img class="product-img" :src="prefix + data.productImg" alt="" />
      <div class="name">
        <div class="main">{{data.name}}</div>
        <div class="sub">{{data.subTitle}}</div>
      </div>
    </div>
    <ul class="skus">
      <li class="card selectable"
        v-for="s in data.skuList"
        :key="s.productSkuId"
        @click="$emit('select', [data.productId, s.productSkuId])"
      >
        <img class="sku-img" :src="prefix + (s.skuPic || data.productImg)" alt="" />
        <div class="name">
          <div class="main">{{s.skuName || data.name}}({{s.price || data.price}}元)</div>
        </div>
        <div class="select">
          <a-radio class="radio" :checked="select[0] === data.productId && select[1] === s.productSkuId"></a-radio>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AProduct',
  props: {
    data: Object,
    select: Array,
  },
  emits: ['select'],
  setup (props) {
    return {
      prefix: process.env.VUE_APP_IMG
    }
  }
}
</script>

<style>
.selectable {
  user-select: none;
  cursor: pointer;
}
.card {
  display: flex;
  padding: 5px;
}
.skus .card {
  align-items: center;
}
.skus .card:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.card img {
  flex: 0 0 auto;
}
.card .select {
  width: 30px;
  flex: 0 0 auto;
}
.card .name {
  flex: 1;
}
.product-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.sku-img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.container {
  display: flex;
  border: 1px solid #eee;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
}
.container>.card {
  flex: 0 0 auto;
  width: 180px;
  border-right: 1px solid #eee;
}
.container>.skus {
  flex: 1;
}
.card .name .main, .card .name .sub {
  line-height: 1.2em;
}
.card .name {
  padding: 0 10px;
}
.skus {
  align-self: center;
}
.skus .card .name {
  padding: 0 2px;
  font-size: 12px;
  color: #666;
}
.card .name .sub {
  font-size: 0.8em;
  color: #999;
  padding-top: 5px;
}
.select .radio {
  margin-top: -3px;
}
</style>
